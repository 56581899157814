/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useState,
} from 'react';
import NewsletterModal from '~/components/Blog/NewsletterModal';
import Subscribe from '~/components/Blog/Subscribe';
import Footer from '~/components/Global/Footer';
import Header from '~/components/Global/Header';
import Nav from '~/components/Global/Nav';
import './../main.scss';

interface LayoutProps {
  children: ReactNode;
  bgcolour?: string;
}

const BlogLayout: FunctionComponent<LayoutProps> = ({
  children,
  bgcolour,
}: LayoutProps): ReactElement => {
  const [navOpen, setNavOpen] = useState(false);
  const [newsletterModalOpen, setNewsletterModalOpen] = useState(false);

  return (
    <>
      {navOpen && <Nav navOpen={navOpen} setNavOpen={setNavOpen} />}
      {newsletterModalOpen && (
        <NewsletterModal
          newsletterModalOpen={newsletterModalOpen}
          setNewsletterModalOpen={setNewsletterModalOpen}
        />
      )}
      <Header
        navOpen={navOpen}
        setNavOpen={setNavOpen}
        newsletterModalOpen={newsletterModalOpen}
        setNewsletterModalOpen={setNewsletterModalOpen}
      />
      <div className={`${bgcolour} blog text-blog-primary`}>
        {children}
        <Subscribe />
        <Footer />
      </div>
    </>
  );
};

export default BlogLayout;
