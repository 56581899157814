import { graphql, Link } from 'gatsby';
import Image from 'gatsby-image';
import React, { FunctionComponent, ReactElement } from 'react';
import Article from '~/components/Blog/Article';
import EventListing from '~/components/Blog/EventListing';
import PreviewText from '~/components/Blog/PreviewText';
import SectionHeader from '~/components/Blog/SectionHeader';
import StaggeredGrid from '~/components/Blog/StaggeredGrid';
import BlogLayout from '~/components/BlogLayout';
import SEO from '~/components/Global/SEO';

const Blog: FunctionComponent = ({ data }): ReactElement => {
  const {
    latestPosts,
    prismicOptions,
    articles,
    causes,
    caseStudies,
    events,
    videos,
    downloads,
  } = data;
  const {
    homepage_hero_title,
    homepage_hero_content,
    events_description,
  } = prismicOptions.data;

  return (
    <BlogLayout>
      <SEO title="Blog" />

      {/* Hero */}
      <section className="blog-hero max-w-screen bg-grey">
        <div className="flex flex-wrap">
          <div className="w-full pt-32 pb-16 md:w-1/2 md:pt-48 lg:pt-56 md:pb-24">
            <div className="px-6 ml-auto mr-0 md:max-w-md xl:max-w-lg md:pr-12 md:pl-6 lg:pl-4 xl:pl-0">
              <div>
                <h1
                  className="font-semibold font-blog-serif text-51px"
                  dangerouslySetInnerHTML={{
                    __html: homepage_hero_title.text,
                  }}
                />
                {homepage_hero_content && <p>{homepage_hero_content}</p>}
              </div>

              {/* Hero - Latest posts */}
              <div className="mt-16 md:mt-32">
                <h4 className="font-blog-serif">Latest</h4>
                {latestPosts.nodes.slice(1).map((post, i) => (
                  <div key={`hero-${i}`} className={i > 0 && 'mt-12'}>
                    <Article {...post} />
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Hero - Latest post */}
          <div className="sticky top-0 w-full h-screen hero-sticky md:w-1/2 bg-body">
            <Link
              to={`/blog/${latestPosts.nodes[0]?.uid}`}
              className="relative flex flex-col justify-end w-full h-full"
            >
              <div className="absolute inset-0 object-cover w-full h-full">
                <Image
                  className="w-full h-full"
                  fluid={latestPosts.nodes[0]?.data?.hero_image?.fluid}
                />
              </div>

              <div className="relative z-10 p-6 pt-32 text-white md:p-12">
                <PreviewText {...latestPosts.nodes[0]} showTeaser={true} />
              </div>
            </Link>
          </div>
        </div>
      </section>

      <main className="pt-12 md:pt-24">
        {/* <Tags /> */}
        {[
          {
            title: 'Articles',
            posts: articles.nodes,
            button: {
              title: 'View more Articles',
              url: '/blog/category/articles/',
            },
          },
          {
            title: 'Causes',
            posts: causes.nodes,
            button: {
              title: 'View more Causes',
              url: '/blog/category/causes/',
            },
          },
        ]
          .filter(({ posts }) => posts.length > 0)
          .map(({ title, posts, button }, i) => (
            <section key={`section-${title}`} className="mar-tb">
              <div className="container">
                <SectionHeader
                  reversed={i % 2 !== 0}
                  title={title}
                  button={button}
                />
                <StaggeredGrid posts={posts} reversed={i % 2 !== 0} />
              </div>
            </section>
          ))}
        <section className="case-studies mar-tb">
          <div className="container">
            <SectionHeader
              title="Case Studies"
              button={{
                title: 'View more Case Studies',
                url: '/blog/category/case-studies/',
              }}
            />
            <div className="grid gap-16 md:grid-cols-2">
              {caseStudies.nodes.map((post, i) => (
                <div key={`case-study-${i}`}>
                  <Article {...post} />
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="events pad-tb bg-blog-green">
          <div className="container">
            <div className="lg:px-16">
              <SectionHeader
                title="Events"
                content={events_description}
                button={{
                  title: 'View more Events',
                  url: '/blog/category/events/',
                }}
              />
              {events.nodes.map((post, i) => (
                <EventListing key={`event-listing-${i}`} {...post} />
              ))}
            </div>
          </div>
        </section>
        <section className="videos bg-lgrey pad-tb">
          <div className="container">
            <div className="text-center">
              <SectionHeader
                title="Videos"
                button={{
                  title: 'View more Videos',
                  url: '/blog/category/videos/',
                }}
                className="flex flex-col items-center justify-center"
              />
            </div>
            <Article {...videos.nodes[0]} />
          </div>
        </section>
        <section className="downloads mar-tb">
          <div className="container">
            <SectionHeader
              title="Downloads"
              button={{
                title: 'View more Downloads',
                url: '/blog/category/downloads/',
              }}
            />
            <div className="grid gap-8 md:grid-cols-3 md:gap-16">
              {downloads.nodes.map((post, i) => (
                <div
                  key={`download-${i}`}
                  className={`${i % 2 === 0 && 'md:pt-12'}`}
                >
                  <Article {...post} />
                </div>
              ))}
            </div>
          </div>
        </section>{' '}
      </main>
    </BlogLayout>
  );
};

export default Blog;

export const query = graphql`
  query {
    prismicOptions {
      data {
        homepage_hero_title {
          text
        }
        homepage_hero_content
        events_description
      }
    }

    latestPosts: allPrismicBlogPost(
      sort: { order: DESC, fields: first_publication_date }
      limit: 4
    ) {
      nodes {
        ...PostPreview
      }
    }

    articles: allPrismicBlogPost(
      filter: { data: { category: { uid: { eq: "articles" } } } }
      sort: { order: DESC, fields: first_publication_date }
      limit: 5
    ) {
      nodes {
        ...PostPreview
      }
    }

    causes: allPrismicBlogPost(
      filter: { data: { category: { uid: { eq: "causes" } } } }
      sort: { order: DESC, fields: first_publication_date }
      limit: 5
    ) {
      nodes {
        ...PostPreview
      }
    }

    caseStudies: allPrismicBlogPost(
      filter: { data: { category: { uid: { eq: "case-studies" } } } }
      sort: { order: DESC, fields: first_publication_date }
      limit: 2
    ) {
      nodes {
        ...PostPreview
      }
    }

    events: allPrismicBlogPost(
      filter: { data: { category: { uid: { eq: "events" } } } }
      sort: { order: DESC, fields: first_publication_date }
      limit: 4
    ) {
      nodes {
        ...PostPreview
      }
    }

    videos: allPrismicBlogPost(
      filter: { data: { category: { uid: { eq: "videos" } } } }
      sort: { order: DESC, fields: first_publication_date }
      limit: 1
    ) {
      nodes {
        ...PostPreview
      }
    }

    downloads: allPrismicBlogPost(
      filter: { data: { category: { uid: { eq: "downloads" } } } }
      sort: { order: DESC, fields: first_publication_date }
      limit: 3
    ) {
      nodes {
        ...PostPreview
      }
    }
  }
`;
