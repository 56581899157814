import { graphql, useStaticQuery } from 'gatsby';
import React, { FunctionComponent, ReactElement } from 'react';
import HubspotForm from '~/components/Global/HubspotForm';

const Nav: FunctionComponent<{
  newsletterModalOpen: boolean;
  setNewsletterModalOpen: () => void;
}> = ({ newsletterModalOpen, setNewsletterModalOpen }): ReactElement => {
  const { prismicOptions } = useStaticQuery(graphql`
    query NewsletterModalQuery {
      prismicOptions {
        data {
          newsletter_subheading {
            text
          }
          newsletter_heading {
            text
          }
          newsletter_images {
            image {
              url
            }
          }
        }
      }
    }
  `);

  const {
    newsletter_subheading,
    newsletter_heading,
    newsletter_images,
  } = prismicOptions.data;

  return (
    <section
      className={`newsletter-modal blog-nav fixed inset-0 min-h-screen w-screen bg-black text-white z-999 overflow-scroll ${newsletterModalOpen &&
        'is-open'}`}
    >
      <div className="flex flex-col items-center flex-auto h-full px-8 pt-24 pb-0 lg:pt-16">
        <div
          className={`flex-auto flex flex-wrap items-center max-w-2xl lg:max-w-4xl leading-none text-center `}
        >
          <div className="flex flex-wrap justify-center">
            <div className="mb-3 font-bold">{newsletter_subheading?.text}</div>
            <h3 className="mb-8 font-semibold leading-none text-white font-blog-serif text-42px sm:text-60px">
              {newsletter_heading?.text}
            </h3>

            <HubspotForm formId={'c34c1060-5111-453c-8166-e0111d683f4e'} />

            <form className="relative flex w-full text-white border-b border-white subscribe-form md:max-w-lg">
              <input
                type="email"
                placeholder="Enter email address"
                className="flex-auto mr-6"
              />
              <input
                type="submit"
                value="Send"
                className="absolute right-0 cursor-pointer form__submit"
              />
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Nav;
