import { Link } from 'gatsby';
import React, { FunctionComponent, ReactElement } from 'react';
import BlogArrow from '~/images/blog/blog-arrow.svg';
interface EventListingProps {
  uid: string;
  data?: {
    title?: {
      text: string;
    };
    event_date: string;
    event_location: string;
    teaser?: string;
  };
}

const EventListing: FunctionComponent<EventListingProps> = (
  props
): ReactElement => {
  const {
    uid,
    data: { title, event_date = '', event_location = '', teaser = '' },
  } = props;

  return (
    <Link
      to={`/blog/${uid}`}
      className="py-10 pt-12 border-t block border-blog-primary"
    >
      <article>
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/3 mb-4">
            <div className="font-bold">{event_location}</div>
            <div>{event_date}</div>
          </div>
          <div className="w-full md:w-2/3">
            <h4 className="text-30px font-semibold">{title?.text}</h4>
            <p>{teaser}</p>
            <div className="font-bold inline-flex items-center mt-6">
              Go to Event
              <BlogArrow className="ml-4" />
            </div>
          </div>
        </div>
      </article>
    </Link>
  );
};

export default EventListing;
