import { Link } from 'gatsby';
import Image from 'gatsby-image';
import React, { FunctionComponent, ReactElement } from 'react';
import PreviewText from '~/components/Blog/PreviewText';
import useClampedIsInViewport from '../../hooks/useWasInViewport';

const Article: FunctionComponent<{}> = (props): ReactElement => {
  const { className = '', uid, data } = props;
  const [isInViewport, articleRef] = useClampedIsInViewport({ threshold: 30 });

  return uid ? (
    <Link
      to={`/blog/${uid}`}
      className={`article-preview w-full max-w-4xl mx-auto block transition duration-700 ease-out ${className}`}
      style={{
        opacity: isInViewport ? 1 : 0,
      }}
      ref={articleRef}
    >
      <div className="mb-6">
        <div className="overflow-hidden img-ratio img-ratio--blog-preview bg-placehold">
          <div className="img-ratio__content">
            <Image
              fluid={data?.hero_image?.fluid}
              className="object-cover w-full h-full transition-transform duration-700 ease-out transform"
            />
          </div>
        </div>
      </div>

      <div
        className="transition-transform duration-700 ease-out"
        style={{
          transform: isInViewport ? 'translateY(10px)' : 'translateY(0)',
        }}
      >
        <PreviewText {...props} />
      </div>
    </Link>
  ) : null;
};

export default Article;
