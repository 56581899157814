import React, { FunctionComponent, ReactElement } from 'react';
import Article from '~/components/Blog/Article';

interface StaggeredGridProps {
  reversed?: boolean;
  posts?: [];
}

const StaggeredGrid: FunctionComponent<StaggeredGridProps> = ({
  reversed = false,
  posts = [],
}): ReactElement => {
  return (
    <div>
      <div className="flex flex-wrap md:-ml-16">
        {posts[0] && (
          <div
            className={`mb-8 md:mb-0 w-full md:w-1/3 md:pl-16 flex items-center ${reversed &&
              'md:order-1'}`}
          >
            <Article {...posts[0]} />
          </div>
        )}
        {posts[1] && (
          <div className="mb-8 md:mb-0  w-full md:w-2/3 md:pl-16">
            <Article {...posts[1]} />
          </div>
        )}
      </div>
      <div className="grid md:grid-cols-3 gap-8 md:gap-16 md:mt-24">
        {posts.slice(2).map((post, i) => (
          <div
            key={`staggered-grid-${i}`}
            className={`${i % 2 === 0 && 'md:pt-12'}`}
          >
            <Article {...post} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default StaggeredGrid;
