import React, { FunctionComponent, ReactElement } from 'react';

interface PreviewTextProps {
  category?: {
    document: {
      data: {
        title: {
          text: string;
        };
      };
    };
  };
  showTeaser: boolean;
  first_publication_date?: string;
  title?: {
    text: string;
  };
  teaser?: string;
}

const PreviewText: FunctionComponent<PreviewTextProps> = ({
  showTeaser = false,
  first_publication_date = 'Oct 20, 2020',
  data,
}): ReactElement => {
  const titleStr = data?.title?.text || '';
  const categoryStr = data?.category?.document?.data?.title?.text || '';

  return titleStr.length > 0 ? (
    <div>
      <div className="text-15px">
        <span className="font-bold">{categoryStr}</span> |{' '}
        {first_publication_date}
      </div>
      <h4 className="font-bold">{titleStr}</h4>
      {showTeaser && <p className="text-15px">{data.teaser}</p>}
    </div>
  ) : null;
};

export default PreviewText;
