// import { Frame, useTransform, useViewportScroll } from 'framer';
import { Link } from 'gatsby';
import React, { FunctionComponent, ReactElement } from 'react';

interface SectionHeaderProps {
  title: string;
  content?: string;
  button?: {
    url?: string;
    title?: string;
  };
  reversed?: boolean;
  className?: string;
}

const SectionHeader: FunctionComponent<SectionHeaderProps> = ({
  title = '',
  button,
  content,
  reversed = false,
  className = '',
}): ReactElement => {
  return (
    <div
      className={`mb-12 ${reversed &&
        'flex flex-col md:items-end md:justify-end'} ${
        className ? className : ''
      }`}
    >
      <h2 className="u-h2 font-blog-serif mb-3 relative">
        {title}
        <span className="heading-background">{title}</span>
      </h2>

      <div className="flex flex-wrap justify-between items-end">
        {content && (
          <div
            className="max-w-md mb-4 md:mb-0"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
        <Link to={button.url} className="font-bold inline-block arrow-right">
          {button.title}
        </Link>
      </div>
    </div>
  );
};

export default SectionHeader;
