import { graphql, useStaticQuery } from 'gatsby';
import React, { FunctionComponent, ReactElement } from 'react';
import HubspotForm from '~/components/Global/HubspotForm';

const Subscribe: FunctionComponent<{}> = (props): ReactElement => {
  const { prismicOptions } = useStaticQuery(graphql`
    query NewsletterQuery {
      prismicOptions {
        data {
          newsletter_subheading {
            text
          }
          newsletter_heading {
            text
          }
          newsletter_images {
            image {
              url
            }
          }
        }
      }
    }
  `);

  const {
    newsletter_subheading,
    newsletter_heading,
    newsletter_images,
  } = prismicOptions.data;

  return (
    <section className="overflow-hidden subscribe pad-tb bg-blog-blue">
      <div className="container">
        <div className="flex flex-wrap -ml-12">
          <div className="w-full pl-12 md:w-7/12">
            <div className="font-bold">{newsletter_subheading?.text}</div>
            <h3 className="font-blog-serif u-h3">{newsletter_heading?.text}</h3>

            <div className="subscribe-form">
              <HubspotForm formId={`c34c1060-5111-453c-8166-e0111d683f4e`} />
            </div>
            {/* <form className="relative flex border-b subscribe-form border-blog-primary md:max-w-lg">
              <input
                type="email"
                placeholder="Enter email address"
                className="flex-auto mr-6"
              />
              <input
                type="submit"
                value="Send"
                className="absolute right-0 cursor-pointer form__submit"
              />
            </form> */}
          </div>
          <div className="justify-end hidden pl-12 -my-6 md:w-5/12 md:flex">
            <div className="relative flex-auto h-full">
              <div className="absolute inset-y-0 left-0 flex -ml-8">
                {newsletter_images.map(({ image }, i) => (
                  <img
                    key={`newsletter-img-${i}`}
                    src={image.url}
                    className="object-cover pl-8 w-72"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
